import { Persona } from '../types';

class PersonaServices {
	async getPersonas(): Promise<Persona[]> {
		try {
			const response = await fetch('/api/getPersonas');
			if (!response.ok) {
				throw new Error(`Failed to fetch personas: ${response.statusText}`);
			}
			const data = await response.json();
			if (Array.isArray(data) && data.every(item => this.isPersona(item))) {
				return data.map(this.parsePersonaId);
			} else {
				console.error('Invalid data format received:', data);
				throw new Error('Invalid data format received from API');
			}
		} catch (error) {
			console.error('Error in getPersonas:', error);
			throw error;
		}
	}

	async getPersona(personaId: string): Promise<Persona> {
		try {
			const url = new URL('/api/getPersona', window.location.origin);
			url.searchParams.append('personaId', personaId);
			const response = await fetch(url.toString());
			if (!response.ok) {
				throw new Error(`Failed to fetch persona with ID ${personaId}: ${response.statusText}`);
			}
			const data = await response.json();
			if (this.isPersona(data)) {
				return this.parsePersonaId(data);
			} else {
				console.error('Invalid data format received:', data);
				throw new Error('Invalid data format received from API');
			}
		} catch (error) {
			console.error(`Error in getPersona for ID ${personaId}:`, error);
			throw error;
		}
	}

	async postPersona(persona: Persona): Promise<Persona> {
		try {
			const response = await fetch('/api/postPersona', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
				},
				body: JSON.stringify(persona),
			});

			if (!response.ok) {
				throw new Error(`Failed to post persona: ${response.statusText}`);
			}

			const data = await response.json();
			console.log('Response data from postPersona:', data);

			if (this.isPersona(data)) {
				return this.parsePersonaId(data);
			} else {
				console.error('Invalid data format received:', data);
				throw new Error('Invalid data format received from API after posting persona');
			}
		} catch (error) {
			console.error('Error in postPersona:', error);
			throw error;
		}
	}

	private isPersona(data: any): data is Persona {
		return (
			typeof data === 'object' && data !== null &&
			'id' in data &&
			'version' in data &&
			'name' in data &&
			'author' in data &&
			'desc' in data &&
			'bootstrap_version' in data &&
			'created_at' in data &&
			'updated_at' in data &&
			'modelName' in data &&
			'provider' in data &&
			'systemMessage' in data
		);
	}

	private parsePersonaId(persona: Persona): Persona {
		const [name, author, version] = persona.id.split('_');
		return {
			...persona,
			name: name || persona.name,
			author: author || persona.author,
			version: version || persona.version
		};
	}
}

export default new PersonaServices();