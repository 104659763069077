import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useToast,
    Button,
    Select,
} from '@chakra-ui/react';
import personaServices from '../services/PersonaServices';

import { Persona } from '../types';

const PROVIDER_OPTIONS = ['groq', 'cerebras'] as const;
type Provider = typeof PROVIDER_OPTIONS[number];

const MODEL_OPTIONS: Record<Provider, string[]> = {
    groq: [
        'gemma-7b-it',
        'gemma2-9b-it',
        'llama-3.1-70b-versatile',
        'llama-3.1-8b-instant',
        'llama-guard-3-8b',
        'llama3-70b-8192',
        'llama3-8b-8192',
        'llama3-groq-70b-8192-tool-use-preview',
        'llama3-groq-8b-8192-tool-use-preview',
        'llava-v1.5-7b-4096-preview',
        'mixtral-8x7b-32768'
    ],
    cerebras: [
        'llama3.1-8b',
        'llama3.1-70b'
    ]
};

const CreatePersonaPage: React.FC = () => {
    const toast = useToast();
    const [personaData, setPersonaData] = useState<Omit<Persona, 'id' | 'created_at' | 'updated_at'>>({
        version: '0.1',
        name: '',
        author: '',
        desc: '',
        bootstrap_version: '2',
        modelName: '',
        provider: PROVIDER_OPTIONS[0],
        systemMessage: '',
    });
    const [isGenerating, setIsGenerating] = useState(false);

    useEffect(() => {
        // Set the default model when the provider changes
        setPersonaData(prev => ({
            ...prev,
            modelName: MODEL_OPTIONS[prev.provider as Provider][0]
        }));
    }, [personaData.provider]);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setPersonaData(prev => ({ ...prev, [name]: value }));
    };

    const handleAutoGenerate = async () => {
        if (!personaData.desc) {
            toast({
                title: 'Missing description',
                description: 'Please provide a persona description before auto-generating the system message.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            return;
        }

        setIsGenerating(true);
        try {
            const response = await fetch('/api/generateSystemMessage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ description: personaData.desc }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate system message');
            }

            const data: unknown = await response.json();

            if (
                typeof data === 'object' &&
                data !== null &&
                'systemMessage' in data &&
                typeof (data as { systemMessage: unknown }).systemMessage === 'string'
            ) {
                setPersonaData(prev => ({
                    ...prev,
                    systemMessage: (data as { systemMessage: string }).systemMessage
                }));
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error instanceof Error ? error.message : 'Failed to auto-generate system message',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
        } finally {
            setIsGenerating(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!personaData.name || !personaData.author || !personaData.desc || !personaData.modelName || !personaData.provider || !personaData.systemMessage) {
            toast({
                title: 'Missing information',
                description: 'Please fill in all required fields',
                status: 'warning',
                duration: 5000,
                isClosable: true,
                position: 'top',
            });
            return;
        }

        try {
            const now = Date.now();
            const newPersona: Persona = {
                ...personaData,
                id: `${personaData.name}_${personaData.author}_${personaData.version}`,
                created_at: now,
                updated_at: now,
            };

            console.log('Sending persona data:', newPersona);
            const response = await personaServices.postPersona(newPersona);
            console.log('Response from personaServices:', response);

            if (response && response.id) {
                toast({
                    title: 'Persona created',
                    description: `Your new persona has been created successfully with ID: ${response.id}`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                });
                // Reset form
                setPersonaData({
                    version: '0.1',
                    name: '',
                    author: '',
                    desc: '',
                    bootstrap_version: '2',
                    modelName: MODEL_OPTIONS[PROVIDER_OPTIONS[0]][0],
                    provider: PROVIDER_OPTIONS[0],
                    systemMessage: '',
                });
            } else {
                throw new Error('Persona creation failed: No ID returned');
            }
        } catch (error) {
            console.error('Error creating persona:', error);
            toast({
                title: 'Error creating persona',
                description: error instanceof Error ? error.message : 'An unexpected error occurred',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
        }
    };

    return (
        <Box maxWidth="600px" margin="auto" padding={8}>
            <Heading as="h1" size="xl" marginBottom={6}>
                Create a New Persona
            </Heading>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4} align="stretch">
                    <FormControl isRequired>
                        <FormLabel>Persona Name</FormLabel>
                        <Input
                            name="name"
                            value={personaData.name}
                            onChange={handleInputChange}
                            placeholder="Enter persona name"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Author Name</FormLabel>
                        <Input
                            name="author"
                            value={personaData.author}
                            onChange={handleInputChange}
                            placeholder="Enter author name"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>About this Persona</FormLabel>
                        <Textarea
                            name="desc"
                            value={personaData.desc}
                            onChange={handleInputChange}
                            placeholder="Describe this Persona"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Provider</FormLabel>
                        <Select
                            name="provider"
                            value={personaData.provider}
                            onChange={handleInputChange}
                        >
                            {PROVIDER_OPTIONS.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>Model Name</FormLabel>
                        <Select
                            name="modelName"
                            value={personaData.modelName}
                            onChange={handleInputChange}
                        >
                            {MODEL_OPTIONS[personaData.provider as Provider].map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel>System Message</FormLabel>
                        <Textarea
                            name="systemMessage"
                            value={personaData.systemMessage}
                            onChange={handleInputChange}
                            placeholder="Enter system message or use Auto Generate"
                        />
                        <Button
                            onClick={handleAutoGenerate}
                            mt={2}
                            isLoading={isGenerating}
                            loadingText="Generating..."
                        >
                            Auto Generate
                        </Button>
                    </FormControl>
                    <Button type="submit" colorScheme="orange" size="lg" width="100%">
                        Create Persona
                    </Button>
                </VStack>
            </form>
        </Box>
    );
};

export default CreatePersonaPage;