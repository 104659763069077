import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box, VStack, Button, Text, Spinner, useToast,
    Heading, Textarea, Menu, MenuButton, MenuList, MenuItem
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import { streamComment } from '../utils/StreamingUtils';
import PersonaServices from '../services/PersonaServices';
import { Persona, ChatMessage } from '../types';

interface PersonaData extends Persona {
    htmlStructure: string;
}

const PersonaRenderer: React.FC = () => {
    const { persona_id } = useParams<{ persona_id: string }>();
    const [personaData, setPersonaData] = useState<PersonaData | null>(null);
    const [userInput, setUserInput] = useState('');
    const [streamedContent, setStreamedContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isStreaming, setIsStreaming] = useState(false);
    const [currentPersona, setCurrentPersona] = useState<Persona | null>(null);
    const [personas, setPersonas] = useState<Persona[]>([]);
    const toast = useToast();

    useEffect(() => {
        const fetchPersonaData = async () => {
            if (!persona_id) return;

            try {
                const response = await fetch(`/api/persona/${persona_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch persona data');
                }
                const data: PersonaData = await response.json();
                setPersonaData(data);
                setCurrentPersona({
                    id: persona_id,
                    version: data.version,
                    name: data.name,
                    author: data.author,
                    desc: data.desc,
                    bootstrap_version: data.bootstrap_version,
                    modelName: data.modelName,
                    provider: data.provider,
                    systemMessage: data.systemMessage,
                    created_at: data.created_at,
                    updated_at: data.updated_at
                });
            } catch (error) {
                console.error('Error fetching persona data:', error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch persona data',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchPersonaData();
    }, [persona_id, toast]);

    useEffect(() => {
        const fetchPersonas = async () => {
            try {
                const fetchedPersonas = await PersonaServices.getPersonas();
                setPersonas(fetchedPersonas);
            } catch (error) {
                console.error("Error fetching personas:", error);
                toast({
                    title: 'Error',
                    description: 'Failed to fetch personas',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        };

        fetchPersonas();
    }, [toast]);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setUserInput(e.target.value);
    };

    const handleStreamComment = useCallback(async () => {
        if (isStreaming || !currentPersona) return;

        setIsStreaming(true);
        setStreamedContent('');

        const messages: ChatMessage[] = [
            { role: "user", content: userInput }
        ];

        try {
            await streamComment(
                messages,
                (content) => setStreamedContent(prev => prev + content),
                (error) => {
                    toast({
                        title: 'Error',
                        description: error,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                },
                () => setIsStreaming(false),
                currentPersona.id,
                currentPersona.provider
            );
        } catch (error) {
            console.error("Error in streamComment:", error);
            toast({
                title: 'Error',
                description: 'Failed to generate comment',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsStreaming(false);
        }
    }, [userInput, currentPersona, toast]);

    const handlePersonaSelect = (persona: Persona) => {
        setCurrentPersona(persona);
    };

    const renderPersonaContent = () => {
        if (!personaData) return null;

        const renderedContent = personaData.htmlStructure.replace('{{content}}', streamedContent);

        return (
            <Box>
                <ReactMarkdown
                    rehypePlugins={[rehypeRaw, rehypeKatex]}
                    remarkPlugins={[remarkMath]}
                >
                    {renderedContent}
                </ReactMarkdown>
            </Box>
        );
    };

    if (isLoading) {
        return <Spinner />;
    }

    if (!personaData) {
        return <Text>Error: Persona not found</Text>;
    }

    return (
        <Box>
            <VStack spacing={4} align="stretch">
                <Heading fontSize="2xl">{personaData.name} by {personaData.author} (v{personaData.version})</Heading>
                <Textarea
                    placeholder="Enter your input here"
                    value={userInput}
                    onChange={handleInputChange}
                />
                <Menu>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        isDisabled={isStreaming}
                    >
                        {currentPersona ? currentPersona.name : "Select Persona"}
                    </MenuButton>
                    <MenuList>
                        {personas.map((persona) => (
                            <MenuItem
                                key={persona.id}
                                onClick={() => handlePersonaSelect(persona)}
                            >
                                {persona.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
                <Button
                    onClick={handleStreamComment}
                    isLoading={isStreaming}
                    loadingText="Generating..."
                    isDisabled={!currentPersona || !userInput.trim()}
                >
                    Generate Response
                </Button>
                {renderPersonaContent()}
            </VStack>
        </Box>
    );
};

export default PersonaRenderer;