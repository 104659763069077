import { ChatMessage, ChatCompletionOptions } from '../types';

export class ChatClient {
    private baseUrl: string;

    constructor(baseUrl: string = "/api") {
        this.baseUrl = baseUrl;
    }

    async chatCompletion(messages: ChatMessage[], options: ChatCompletionOptions = {}): Promise<ReadableStream<Uint8Array> | null> {
        const data = {
            messages: messages,
            options: {
                model: options.model,
                persona_id: options.persona_id,
                provider: options.provider, // Add the provider option
            }
        };

        try {
            const response = await fetch(`${this.baseUrl}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response.body;
        } catch (error) {
            console.error("An error occurred:", error);
            return null;
        }
    }
}